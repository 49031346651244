<div class="kt-login__form">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <i class="flaticon-line-graph mr-2"></i>
                <span>Dashboard {{ appSession.tenantId != null ? 'QAMetrik' : appSession.tenancyName }}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{ appSession.tenantId != null
                ? "Selecione os gráficos que deseja visualizar no menu abaixo"
                : 'Gráficos Host' }}
            </span>
        </div>
        <div class="kt-subheader__toolbar" *ngIf="appSession.tenantId != null">
            <div class="config kt-subheader__wrapper" (click)="visible = true">
                <i class="fas fa-cog mr-1"></i>
                <span>Configurações</span>
            </div>
        </div>
    </div>
    <p-menubar [model]="items" *ngIf="isMenu"></p-menubar>
    <div class="mt-4" *ngIf="appSession.tenantId == null">
        <app-graphics *ngIf="isGraphics"></app-graphics>
        <app-saving-global *ngIf="isSaving" [copyTransportTime]="copyTransportTime"
            [professionalPrice]="professionalPrice" [productiveTransportTime]="productiveTransportTime">
        </app-saving-global>
        <app-results-obtained *ngIf="isResults"></app-results-obtained>
    </div>
    <div *ngIf="appSession.tenantId != null && isMenu">
        <div class="mt-4" *ngIf="isRework">
            <app-rework [tenantId]="appSession.tenantId"></app-rework>
        </div>
        <div class="mt-4" *ngIf="isCostReduction">
            <app-cost-reduction [tenantId]="appSession.tenantId"></app-cost-reduction>
        </div>
        <div class="mt-4" *ngIf="isNonCompliance">
            <app-non-compliance [tenantId]="appSession.tenantId"></app-non-compliance>
        </div>
        <div class="mt-4" *ngIf="isGmud">
            <app-gmud [tenantId]="appSession.tenantId"></app-gmud>
        </div>
        <div class="mt-4" *ngIf="isRequest">
            <app-request [tenantId]="appSession.tenantId"></app-request>
        </div>
        <div class="mt-4" *ngIf="isEnvironmentalAssessment">
            <app-environment-assessment [tenantId]="appSession.tenantId"></app-environment-assessment>
        </div>
        <div class="mt-4" *ngIf="isTimeline">
            <app-timeline-demanda [tenantId]="appSession.tenantId"></app-timeline-demanda>
        </div>
        <div class="mt-4" *ngIf="isSaving">
            <app-saving-global [copyTransportTime]="copyTransportTime" [professionalPrice]="professionalPrice"
                [productiveTransportTime]="productiveTransportTime">
            </app-saving-global>
        </div>
        <div class="mt-4" *ngIf="isReqChanges">
            <app-req-changes [tenantId]="appSession.tenantId">
            </app-req-changes>
        </div>
    </div>
    <div *ngIf="appSession.tenantId != null && !isMenu">
        <span class="no-dash">Nenhum gráfico ativo</span>
    </div>

    <p-toast></p-toast>
    <p-dialog header="Configurações" [style]="{ width: '350px' }" [modal]="true" [(visible)]="visible"
        *ngIf="appSession.tenantId != null">
        <p-tabView [(activeIndex)]="activeIndex">
            <p-tabPanel header="Gráficos">
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isRework"
                        (click)="changeOption(0, appSession.configDashs.isRework)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Retrabalho</label>
                </div>
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isCostReduction"
                        (click)="changeOption(1, appSession.configDashs.isCostReduction)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Análise Code Review</label>
                </div>

                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isNonCompliance"
                        (click)="changeOption(2, appSession.configDashs.isNonCompliance)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Não Conformidade</label>
                </div>
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isGmud"
                        (click)="changeOption(3, appSession.configDashs.isGmud)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Gmud's</label>
                </div>
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isRequest"
                        (click)="changeOption(4, appSession.configDashs.isRequest)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Request's</label>
                </div>
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isEnvironmentalAssessment"
                        (click)="changeOption(5, appSession.configDashs.isEnvironmentalAssessment)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Avaliação de Ambiente</label>
                </div>
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isTimeline"
                        (click)="changeOption(7, appSession.configDashs.isTimeline)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Timeline Demandas</label>
                </div>
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isSaving"
                        (click)="changeOption(6, appSession.configDashs.isSaving)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Saving Global</label>
                </div>
                <div class="d-flex align-items-center">
                    <p-inputSwitch [(ngModel)]="appSession.configDashs!.isReqChanges"
                        (click)="changeOption(8, appSession.configDashs.isReqChanges)">
                    </p-inputSwitch>
                    <label class="mr-3 ml-3 mb-0">Requisições de Mudança</label>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Saving" *ngIf="isSavingMenu">
                <form [formGroup]="configTimeSavingForm">
                    <div class="pl-5">
                        <label>Tempo Transporte Cópia (mm)</label>
                        <p-inputNumber formControlName="copyTransportTime" suffix=" min"
                            [(ngModel)]="configTimeSaving.copyTransportTime">
                        </p-inputNumber>
                    </div>
                    <div class="pl-5 mt-3">
                        <label>Tempo Transporte Produtivo (mm)</label>
                        <p-inputNumber formControlName="productiveTransportTime" suffix=" min"
                            [(ngModel)]="configTimeSaving.productiveTransportTime">
                        </p-inputNumber>
                    </div>
                    <div class="pl-5 mt-3">
                        <label>Valor Profissional (hr)</label>
                        <p-inputNumber formControlName="professionalPrice" mode="currency" currency="BRL" locale="pt-BR"
                            [(ngModel)]="configTimeSaving.professionalPrice">
                        </p-inputNumber>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <p-button label="Salvar" (click)="onSubmit()"></p-button>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
