<div bsModal #createOrEditModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-backdrop fade in show" style="z-index: 1051" *ngIf="backdrop"></div>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #userForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="new-password">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="user.id">{{"EditUser" | localize}}</span>
                        <span *ngIf="!user.id">{{"CreateNewUser" | localize}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')"
                        [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab heading="{{'UserInformations' | localize}}">
                            <ng-template tabHeading>
                                <i *ngIf="nameInput.invalid ||surnameInput.invalid  "
                                    class="fas fa-exclamation-circle exclamation-tab"></i>
                            </ng-template>

                            <div class="row">
                                <div class="col-sm-3 text-center mb-5 mt-5">
                                    <img src="{{profilePicture}}" width="128" height="128"
                                        class="img-thumbnail img-rounded" />
                                </div>
                                <div class="col-sm-9">
                                    <div class="form-group">
                                        <label for="Name">{{"Name" | localize}} *</label>
                                        <input id="Name" #nameInput="ngModel" class="form-control" type="text"
                                            name="Name" [(ngModel)]="user.name" required
                                            [ngClass]="{'is-invalid': nameInput.invalid, 'is-valid': !nameInput.invalid}"
                                            maxlength="32">
                                        <validation-messages [formCtrl]="nameInput"></validation-messages>
                                    </div>

                                    <div class="form-group">
                                        <label for="Surname">{{"Surname" | localize}} *</label>
                                        <input id="Surname" #surnameInput="ngModel" type="text" name="Surname"
                                            class="form-control" [(ngModel)]="user.surname" required
                                            [ngClass]="{'is-invalid': surnameInput.invalid, 'is-valid': !surnameInput.invalid}"
                                            maxlength="32">
                                        <validation-messages [formCtrl]="surnameInput"></validation-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} *</label>
                                <input id="EmailAddress" #emailAddressInput="ngModel" type="email" name="EmailAddress"
                                    class="form-control" [(ngModel)]="user.emailAddress" required maxlength="256"
                                    [ngClass]="{'is-invalid': emailAddressInput.invalid, 'is-valid': !emailAddressInput.invalid}"
                                    email />
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>
                            <div class="form-group">
                                <label for="UserName">{{"UserName" | localize}} *</label>
                                <input id="UserName" #userNameInput="ngModel" type="text"
                                    [disabled]="!canChangeUserName || appSession.tenantId" name="UserName"
                                    class="form-control" [(ngModel)]="user.userName" required
                                    [ngClass]="{'is-invalid': userNameInput.invalid, 'is-valid': !userNameInput.invalid}"
                                    maxlength="32">
                                <span class="help-block" *ngIf="!canChangeUserName">{{"CanNotChangeAdminUserName" |
                                    localize}}</span>
                                <validation-messages [formCtrl]="userNameInput"></validation-messages>
                            </div>
                            <div class="form-group">
                                <label for="Cpf">{{"Cpf" | localize}}</label>
                                <input id="Cpf" #cpfnput="ngModel" type="text" name="Cpf" class="form-control"
                                    (keyup)="validarCPF($event)" [(ngModel)]="user.cpf" mask="000.000.000-00"
                                    maxlength="80" [disabled]=""
                                    [ngClass]="{'is-invalid': cpfnput.invalid, 'is-valid': !cpfnput.invalid}">
                                <validation-messages [formCtrl]="cpfnput"></validation-messages>
                                <label class="text-danger" *ngIf="!cpfValido"> {{l("CpfInvalido")}}</label>
                                <label class="text-danger" *ngIf="!cpfUnico"> {{l("CpfJaUtilizado")}}</label>
                            </div>
                            <div class="form-group" *ngIf="appSession.tenantId">
                                <label for="Fornecedor">{{"Fornecedor" | localize}} </label>
                                <ng-select [items]="fornecedores" [multiple]="false" [(ngModel)]="fornecedorSelected"
                                    (ngModelChange)="validarFornecedor($event)" name="fornecedorSelected">
                                </ng-select>
                                <label class="text-danger"
                                    *ngIf="fornecedorSelected != null && !fornecedorSelected.active && user.fornecedorId == fornecedorSelected.value">
                                    {{l("InactiveProviderError")}}
                                </label>
                            </div>
                            <div class="form-group" *ngIf="appSession.tenantId">
                                <label for="Cargo">{{"Cargo" | localize}} </label>
                                <ng-select [items]="cargos" [multiple]="false" [(ngModel)]="cargosSelected"
                                    name="cargosSelected">
                                </ng-select>
                            </div>
                            <div class="form-group" *ngIf="appSession.tenantId">
                                <label for="UsuarioSap">{{"UsuarioSap" | localize}} </label>
                                <ng-select [items]="usuariosSapsDisponiveis" [multiple]="false"
                                    [(ngModel)]="usuarioSapSelected" [virtualScroll]=true (scroll)="onScroll($event)"
                                    name="usuarioSapSelected">
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item.label}}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                        <div class="p-2">
                                            <b>{{item.label}}</b>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="form-group" *ngIf="appSession.tenantId">
                                <label for="DataDeExpiracaoDeAcesso">{{l("DataDeExpiracaoDeAcesso")}}</label>
                                <input [(ngModel)]="dataDeExpiracaoDeAcesso" bsDatepicker class="form-control m-input"
                                    id="DataDeExpiracaoDeAcesso" name="DataDeExpiracaoDeAcesso" type="datetime"
                                    [bsConfig]="{ adaptivePosition: true }">
                            </div>
                            <div class="kt-checkbox-list" *ngIf="!appSession.tenantId">
                                <label for="EditUser_SetRandomPassword" class="kt-checkbox">
                                    <input id="EditUser_SetRandomPassword" type="checkbox" name="SetRandomPassword"
                                        [(ngModel)]="setRandomPassword">
                                    {{"SetRandomPassword" | localize}}
                                    <span></span>
                                </label>
                            </div>

                            <div class="form-group" *ngIf="!setRandomPassword && (!appSession.tenantId )">
                                <div class="right">
                                    <label for="Password">{{"Password" | localize}}</label>
                                    <input id="Password" type="password" name="Password" #Password="ngModel"
                                        id="Password" class="form-control" [(ngModel)]="user.password"
                                        [required]="!user.id && !setRandomPassword" maxlength="32"
                                        [requireDigit]="passwordComplexitySetting.requireDigit"
                                        [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters"
                                        [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                        [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                        [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                        [ngClass]="{'is-invalid': Password.invalid, 'is-valid': !Password.invalid}"
                                        [requiredLength]="passwordComplexitySetting.requiredLength"
                                        validateEqual="PasswordRepeat" reverse="true">
                                </div>
                            </div>
                            <div [hidden]="setRandomPassword || userForm.form.valid || userForm.form.pristine"
                                *ngIf="!appSession.tenantId  ">
                                <ul class="help-block text-danger"
                                    *ngIf="userForm.controls['Password'] && userForm.controls['Password'].errors">
                                    <li [hidden]="!userForm.controls['Password'].errors.requireDigit">
                                        {{"PasswordComplexity_RequireDigit_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['Password'].errors.requireLowercase">
                                        {{"PasswordComplexity_RequireLowercase_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['Password'].errors.requireUppercase">
                                        {{"PasswordComplexity_RequireUppercase_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['Password'].errors.requireNonAlphanumeric">
                                        {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['Password'].errors.requiredLength">
                                        {{"PasswordComplexity_RequiredLength_Hint" |
                                        localize:passwordComplexitySetting.requiredLength}}
                                    </li>
                                    <li [hidden]="!userForm.controls['Password'].errors.notAllowSequencing">
                                        {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group" *ngIf="!setRandomPassword && ( !appSession.tenantId ) ">
                                <div class="right">
                                    <label for="PasswordRepeat">{{"PasswordRepeat" | localize}}</label>
                                    <input id="PasswordRepeat" type="password" name="PasswordRepeat"
                                        #PasswordRepeat="ngModel" class="form-control" [(ngModel)]="user.passwordRepeat"
                                        [required]="!user.id && !setRandomPassword" maxlength="32"
                                        [requireDigit]="passwordComplexitySetting.requireDigit"
                                        [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters"
                                        [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                        [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                        [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                        [ngClass]="{'is-invalid': PasswordRepeat.invalid, 'is-valid': !PasswordRepeat.invalid}"
                                        [requiredLength]="passwordComplexitySetting.requiredLength"
                                        validateEqual="Password" reverse="false">
                                </div>
                            </div>
                            <div [hidden]="setRandomPassword || userForm.form.valid || userForm.form.pristine">
                                <ul class="help-block text-danger"
                                    *ngIf="userForm.controls['PasswordRepeat'] && userForm.controls['PasswordRepeat'].errors">
                                    <li [hidden]="!userForm.controls['PasswordRepeat'].errors.requireDigit">
                                        {{"PasswordComplexity_RequireDigit_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['PasswordRepeat'].errors.requireLowercase">
                                        {{"PasswordComplexity_RequireLowercase_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['PasswordRepeat'].errors.requireUppercase">
                                        {{"PasswordComplexity_RequireUppercase_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['PasswordRepeat'].errors.requireNonAlphanumeric">
                                        {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['PasswordRepeat'].errors.requiredLength">
                                        {{"PasswordComplexity_RequiredLength_Hint" |
                                        localize:passwordComplexitySetting.requiredLength}}
                                    </li>
                                    <li [hidden]="userForm.controls['PasswordRepeat'].valid">
                                        {{"PasswordsDontMatch" | localize}}
                                    </li>
                                    <li [hidden]="!userForm.controls['PasswordRepeat'].errors.notAllowSequencing">
                                        {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                                    </li>
                                </ul>
                            </div>
                            <div class="kt-checkbox-list">
                                <label for="EditUser_ShouldChangePasswordOnNextLogin" class="kt-checkbox"
                                    *ngIf="!appSession.tenantId">
                                    <input id="EditUser_ShouldChangePasswordOnNextLogin" type="checkbox"
                                        name="ShouldChangePasswordOnNextLogin"
                                        [(ngModel)]="user.shouldChangePasswordOnNextLogin">
                                    {{"ShouldChangePasswordOnNextLogin" | localize}}
                                    <span></span>
                                </label>

                                <label for="EditUser_SendActivationEmail" class="kt-checkbox">
                                    <input id="EditUser_SendActivationEmail" type="checkbox" name="SendActivationEmail"
                                        [(ngModel)]="sendActivationEmail">
                                    {{"SendActivationEmail" | localize}}
                                    <span></span>
                                </label>

                                <label for="EditUser_IsActive" class="kt-checkbox">
                                    <input id="EditUser_IsActive" type="checkbox" name="IsActive"
                                        [(ngModel)]="user.isActive">
                                    {{"Active" | localize}}
                                    <span></span>
                                </label>

                                <label class="kt-checkbox" *ngIf="user.aiEnabledForBusiness && isDisableCheck">
                                    <input type="checkbox" name="aiSettingsAreActive"
                                        [(ngModel)]="user.aiSettingsAreActive">
                                        QACODAI
                                    <span></span>
                                </label>

                                <label *ngIf="isTwoFactorEnabled" for="EditUser_IsTwoFactorEnabled" class="kt-checkbox">
                                    <input id="EditUser_IsTwoFactorEnabled" type="checkbox" name="IsTwoFactorEnabled"
                                        [(ngModel)]="user.isTwoFactorEnabled">
                                    {{"IsTwoFactorEnabled" | localize}}
                                    <span></span>
                                </label>

                                <label *ngIf="isLockoutEnabled && !appSession.tenantId" for="EditUser_IsLockoutEnabled"
                                    class="kt-checkbox">
                                    <input id="EditUser_IsLockoutEnabled" type="checkbox" name="IsLockoutEnabled"
                                        [(ngModel)]="user.isLockoutEnabled">
                                    {{"IsLockoutEnabled" | localize}}
                                    <span></span>
                                </label>
                            </div>
                            <p-messages [(value)]="messages" [enableService]="false" [closable]="false"
                                *ngIf="user.aiEnabledForBusiness && user.aiEnabledForAllUsers && !isDisableCheck">
                            </p-messages>
                        </tab>
                        <tab *ngIf="!appSession.tenantId" heading="{{'Contato' | localize}}">
                            <div class="kt-checkbox-list">
                                <div class="form-group">
                                    <label for="PhoneNumber">{{"PhoneNumber" | localize}}</label>
                                    <input id="PhoneNumber" type="text" name="PhoneNumber" class="form-control"
                                        [(ngModel)]="user.phoneNumber" maxlength="24" mask="(00) 00000-0000">
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Facebook">Facebook </label>
                                            <input id="Facebook" #facebookInput="ngModel" class="form-control"
                                                type="text" name="Facebook" [(ngModel)]="user.facebook" maxlength="200">
                                            <validation-messages [formCtrl]="facebookInput"></validation-messages>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Skype">Skype </label>
                                            <input id="Skype" #skypeInput="ngModel" class="form-control" type="text"
                                                name="Skype" [(ngModel)]="user.skype" maxlength="200">
                                            <validation-messages [formCtrl]="skypeInput"></validation-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Linkedin">Linkedin </label>
                                            <input id="Linkedin" #linkedinInput="ngModel" class="form-control"
                                                type="text" name="Linkedin" [(ngModel)]="user.linkedin" maxlength="200">
                                            <validation-messages [formCtrl]="linkedinInput"></validation-messages>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Twitter">Twitter </label>
                                            <input id="Twitter" #twitterInput="ngModel" class="form-control" type="text"
                                                name="Twitter" [(ngModel)]="user.twitter" maxlength="200">
                                            <validation-messages [formCtrl]="twitterInput"></validation-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="{{'Roles' | localize}}">
                            <ng-template tabHeading>
                                <span class="kt-badge kt-badge--success kt-badge--inline">{{getAssignedRoleCount()}}
                                </span>
                            </ng-template>
                            <div class="kt-checkbox-list">
                                <label attr.for="EditUser_{{role.roleName}}" class="kt-checkbox"
                                    *ngFor="let role of roles">
                                    <input id="EditUser_{{role.roleName}}" type="checkbox" name="{{role.roleName}}"
                                        [(ngModel)]="role.isAssigned">
                                    {{role.roleDisplayName}}
                                    <span></span>
                                </label>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{"Cancel" |
                        localize}}
                    </button>
                    <button type="submit" class="btn btn-primary"
                        [disabled]="!userForm.form.valid || !cpfValido || fornecedorInativo || !cpfUnico"
                        [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
