import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChangeRequestForApprovalDto, ChangeRequestServiceProxy } from "@shared/service-proxies/service-proxies";
import { HistorysChangeRequestModalComponent } from "./history-change-request-modal/history-change-request-modal.component";

@Component({
    selector: 'historyChangeRequestModal',
    templateUrl: './historyChangeRequestModal.component.html',
    styleUrls: ['./historyChangeRequestModal.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class HistoryApprovalChangeRequestComponent extends AppComponentBase implements OnInit {
    @ViewChild('historysChangeRequestModal', { static: true }) historysChangeRequestModal: HistorysChangeRequestModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    titleFilter: string = '';
    statusFilter: number = -1;
    maxDatetimeFilter: moment.Moment;
    minDatetimeFilter:moment.Moment;
    changeRequestForApprovalDto: ChangeRequestForApprovalDto = new ChangeRequestForApprovalDto;

    constructor(
        injector: Injector,
        private _changeRequestAppService: ChangeRequestServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void { }
    
    resetarFiltros() { }

    getLocaleDateHour(date: moment.Moment) {
        if (date === undefined) return "";
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    reloadGrid(event?: LazyLoadEvent) {
        this.getAllHistoryApprovalChangeRequests(event);
    }

    getAllHistoryApprovalChangeRequests(event?: LazyLoadEvent) {    
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._changeRequestAppService.getAllChangeRequestsForHistory(
            this.titleFilter,
            this.statusFilter,
            this.maxDatetimeFilter,
            this.minDatetimeFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });
    }

    getStatusColor(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'success';
            case 'Em análise':
                return 'warning';
            case 'Reagendado':
                return 'warning';
            case 'Reprovado':
                return 'danger';
            case 'Cancelado':
                return 'danger';
            case 'Implementado':
                return 'success';
            default:  return 'info'; 
        }
    }

    reloadPage(ev: any) {
        this.reloadGrid(ev);
    }

    showChangeRequest(changeRequestId: string): void {
        this._changeRequestAppService.getChangeRequestForHistoryScreen(changeRequestId)
            .subscribe(res => { 
                this.changeRequestForApprovalDto = res;
                this.historysChangeRequestModal.show(this.changeRequestForApprovalDto);
            }); 

        this.historysChangeRequestModal.show(this.changeRequestForApprovalDto);
    }
}