<div class="kt-subheader kt-grid__item">
    <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">
            <i class="fa fa-laptop-code mr-2"></i>
            <span>QACODAI</span>
        </h3>
    </div>
</div>
<div class="container px-4 py-5">
    <h3 class="pb-2 border-bottom">Selecione o Prompt</h3>
    <div class="row justify-content-center py-5">
        <div class="sm:col-12 md:col-12 lg:col-3 mb-4 mr-4 card card-cover overflow-hidden text-bg-dark rounded-4 border border-secondary"
            *ngFor="let promptAIConfig of promptAIConfigOptions">
            <div class="d-flex flex-column p-5 pb-3 text-dark text-shadow-1" style="width: 270px;"
                (click)="setPrompt(promptAIConfig.value, promptAIConfig.label)">
                <i [class]="promptAIConfig.icon" style="font-size: 11pt;"></i>
                <div class="mt-2">
                    <h6 class="fw-bold">{{promptAIConfig.label}}</h6>
                </div>
            </div>
        </div>
    </div>
</div>
