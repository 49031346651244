import { Component, Input, OnInit, Injector } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'app-req-changes',
  templateUrl: './req-changes.component.html',
  styleUrls: ['./req-changes.component.less']
})
export class ReqChangesComponent implements OnInit {

    @Input() tenantId: number = 0;
    urlQuestion: SafeUrl;
    currentDate: string = "";
    initDate180DaysAgo: string = "";

    constructor(
      private domSanitizer : DomSanitizer
    ) {
        this.getDates();
    }

    ngOnInit() {
        this.mounthGraphics();
    }

    mounthGraphics() {
      this.urlQuestion = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://app1.qametrik.com/public/question/087c9cc8-4210-4a2b-9829-3da65a24c276?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`);
    }

    getDates() {
        var currentDate = new Date();
        var initDate = new Date();

        initDate.setDate(initDate.getDate() - 180);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate180DaysAgo = initDate.toISOString().slice(0, 10);
    }
  }
