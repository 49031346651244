import { ChangeDetectorRef, Component, EventEmitter, HostListener, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChangeRequestConfigServiceProxy, ChangeRequestFilesDto, ChangeRequestServiceProxy, CreateChangeRequestDto, EnumRdmRiskLevel, EnumRdmTypeOfChange, RdmAttachedDocsDto, RdmClassificationDto, RdmDepartamentDto, RdmSettingsDto, RdmUnavailabilityDto } from "@shared/service-proxies/service-proxies";
import { IAjaxResponse, TokenService } from "abp-ng2-module";
import moment from "moment";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SelectItem, TreeNode } from "primeng/api";
import * as uuid from 'uuid';

@Component({
    selector: 'create-or-edit-change-request-modal',
    templateUrl: './create-or-edit-change-request-modal.component.html',
    styleUrls: ['./create-or-edit-change-request-modal.component.less']
})

export class CreateOrEditChangeRequestModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createEditChangeRequestModal', { static: true }) createEditChangeRequestModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void { event.preventDefault(); event.stopPropagation(); }
    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void { event.preventDefault(); event.stopPropagation(); }
    @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        
        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
          this.onFilesAdded(null,files);
        }
    };

    loading: boolean = false;
    saving: boolean = false;
    isEmergency: boolean = false;
    createChangeRequestDto: CreateChangeRequestDto = new CreateChangeRequestDto;
    typesChange: SelectItem[] = [];
    riskLevel: SelectItem[] = [];
    rdmClassificationType: RdmClassificationDto[] = [];
    rdmClassificationTypeSelected: RdmClassificationDto[] = [];
    rdmUnavailabilityType: RdmUnavailabilityDto[] = [];
    rdmUnavailabilityTypeSelected: RdmUnavailabilityDto[] = [];
    rdmAttachedDocsType: RdmAttachedDocsDto[] = [];
    rdmAttachedDocsTypeSelected: RdmAttachedDocsDto[] = [];
    addedAttachments: ChangeRequestFilesDto[] = [];
    guidAddedAttachments: string;
    usersApproval: TreeNode[] = [];
    usersApprovalSelected: TreeNode[] = [];
    unavailabilityEndDate: Date = new Date;
    unavailabilityInitialDate: Date = new Date;
    hoursDifference: string;
    rdmSettingsDto: RdmSettingsDto = new RdmSettingsDto;
    public uploader: FileUploader;
    private _uploaderOptions: FileUploaderOptions = { url: "" };
    formGroup = new FormGroup({ typeOfChange: new FormControl(null), riskLevel: new FormControl(null) });
    isEdit: boolean = false;

    ngOnInit(): void {
        this.selectRdmClassificationType();
        this.selectRdmUnavailabilityType();
        this.selectRdmAttachedDocsType();
        this.loadTheDepartments(null);

        this.formGroup = new FormGroup({
            typeOfChange: new FormControl(null),
            riskLevel: new FormControl(null)
          });
    }

    constructor(
        injector: Injector,
        private _changeRequestAppService: ChangeRequestServiceProxy,
        private _changeRequestConfigAppService: ChangeRequestConfigServiceProxy,
        private _tokenAppService: TokenService,
        private cdr: ChangeDetectorRef
    ) {
        super(injector);
        this.setOptions();
        this.rdmSettings();
    }    
    
    async show(createChangeRequestDto: CreateChangeRequestDto, button: boolean) {
        this.rdmClassificationTypeSelected = [];
        this.rdmUnavailabilityTypeSelected = [];
        this.rdmAttachedDocsTypeSelected = [];
        this.addedAttachments = [];
        this.unavailabilityInitialDate = null;
        this.unavailabilityEndDate = null;
        this.hoursDifference = '';
        
        if (button){    
            this.createChangeRequestDto = new CreateChangeRequestDto;
            this.createEditChangeRequestModal.show();
            this.isEdit = false;
            return;
        }

        this.isEdit = true;
        this.createChangeRequestDto = createChangeRequestDto;
        this.addedAttachments = createChangeRequestDto.files;
        this.unavailabilityInitialDate = createChangeRequestDto.unavailabilityInitialDate.toDate();
        this.unavailabilityEndDate = createChangeRequestDto.unavailabilityEndDate.toDate();

        this._changeRequestAppService.getSelectedClassificationTypes(createChangeRequestDto.id)
            .subscribe(x => { this.rdmClassificationTypeSelected = x; });   

        this._changeRequestAppService.getSelectedUnavailabilityTypes(createChangeRequestDto.id)
            .subscribe(x => { this.rdmUnavailabilityTypeSelected = x; });               

        this._changeRequestAppService.getSelectedAttachedDocsTypes(createChangeRequestDto.id)
            .subscribe(x => { this.rdmAttachedDocsTypeSelected = x; });  
            
        this.loadTheDepartments(this.createChangeRequestDto.departamentsList);
        this.calculateHours();
          
        this.formGroup.get('typeOfChange').setValue(this.createChangeRequestDto.typeOfChange);
        this.formGroup.get('riskLevel').setValue(this.createChangeRequestDto.riskLevel);

        this.createEditChangeRequestModal.show();
    }
    
    close(): void {
        this.createEditChangeRequestModal.hide();
        this.closed.emit();
        this.createChangeRequestDto = new CreateChangeRequestDto;
        this.rdmClassificationTypeSelected = [];
        this.rdmUnavailabilityTypeSelected = [];
        this.rdmAttachedDocsTypeSelected = [];
        this.createChangeRequestDto.typeOfChange = EnumRdmTypeOfChange.Normal;
        this.createChangeRequestDto.riskLevel = EnumRdmRiskLevel.Low;
        this.addedAttachments = [];
        this.unavailabilityEndDate = new Date;
        this.unavailabilityInitialDate = new Date;
        if (this.rdmSettingsDto != null && !this.rdmSettingsDto.approvalLevelsIsActive) this.usersApprovalSelected = [];
        this.isEdit = false;
    }

    setOptions() {
        this.typesChange.push({ label: "Normal", value: EnumRdmTypeOfChange.Normal });
        this.typesChange.push({ label: "Padrão", value: EnumRdmTypeOfChange.Standard });
        this.typesChange.push({ label: "Emergencial", value: EnumRdmTypeOfChange.Emergency });

        this.riskLevel.push({ label: "Baixo", value: EnumRdmRiskLevel.Low });
        this.riskLevel.push({ label: "Médio", value: EnumRdmRiskLevel.Average });
        this.riskLevel.push({ label: "Alto", value: EnumRdmRiskLevel.High });
        this.riskLevel.push({ label: "Crítico", value: EnumRdmRiskLevel.Critical });
    }

    saveChangeRequest() {
        this.loading = true;
        if (!this.validateForm()) {
            this.notify.warn('Existem campos obrigatórios que não foram informados, verifique!');
            this.loading = false;
            return;
        }

        if (moment(this.unavailabilityInitialDate).isBefore(moment())) {
            this.notify.warn("A data informada não pode ser anterior a hoje.");
            this.loading = false;
            return;
        }

        if (this.unavailabilityEndDate < this.unavailabilityInitialDate){
            this.notify.warn('Data final não pode ser menor que a inicial.');
            this.loading = false;
            return;
        }

        this.createChangeRequestDto.rdmClassificationList = [];
        this.createChangeRequestDto.unavailabilityList = [];
        this.createChangeRequestDto.attachedDocsList = [];
        this.createChangeRequestDto.mandatoryApprovingUsers = [];
        this.createChangeRequestDto.files = [];

        if (this.rdmClassificationTypeSelected != null && this.rdmClassificationTypeSelected != undefined &&
            this.rdmClassificationTypeSelected.length > 0) {
                this.rdmClassificationTypeSelected.forEach(classification => {
                    this.createChangeRequestDto.rdmClassificationList.push(classification.id);
                });
        }

        if (this.rdmUnavailabilityTypeSelected != null && this.rdmUnavailabilityTypeSelected != undefined &&
            this.rdmUnavailabilityTypeSelected.length > 0) {
                this.rdmUnavailabilityTypeSelected.forEach(unavailability => {
                    this.createChangeRequestDto.unavailabilityList.push(unavailability.id);
                });
        }

        if (this.rdmAttachedDocsTypeSelected != null && this.rdmAttachedDocsTypeSelected != undefined &&
            this.rdmAttachedDocsTypeSelected.length > 0) {
                this.rdmAttachedDocsTypeSelected.forEach(attached => {
                    this.createChangeRequestDto.attachedDocsList.push(attached.id);
                });
        }

        if (this.usersApprovalSelected != null && this.usersApprovalSelected != undefined &&
            this.usersApprovalSelected.length > 0) {
                this.usersApprovalSelected.forEach(userApproval => {
                    this.createChangeRequestDto.mandatoryApprovingUsers.push(userApproval.data?.id);
                });
        }

        if (this.addedAttachments != null && this.addedAttachments != undefined &&
            this.addedAttachments.length > 0) {
                this.addedAttachments.forEach(addedAttachment => {
                    this.createChangeRequestDto.files.push(addedAttachment);
                });
        }

        this.createChangeRequestDto.unavailabilityInitialDate = moment(this.unavailabilityInitialDate);
        this.createChangeRequestDto.unavailabilityEndDate = moment(this.unavailabilityEndDate);

        this._changeRequestAppService.createOrUpdateChangeRequest(this.createChangeRequestDto)
            .subscribe(res => {
                this.notify.success('RDM salvo com sucesso.');
                this.loading = false;
                this.close();
            }); 
    }

    onValueChangeType(newValue: any) {
        this.createChangeRequestDto.typeOfChange = newValue.value;      
        this.isEmergency = newValue.value == EnumRdmTypeOfChange.Emergency ? true : false;
    }

    onValueRiskLevel(newValue: any) {
        this.createChangeRequestDto.riskLevel = newValue.value;
    }

    selectRdmClassificationType(): void {
        this.rdmClassificationTypeSelected = [];
        this._changeRequestAppService.getClassificationTypes()
            .subscribe(x => { this.rdmClassificationType = x; });
    }

    clearRdmClassificationType(item: RdmClassificationDto) {
        this.rdmClassificationTypeSelected = [].concat(this.rdmClassificationTypeSelected.filter(x => x.id !== item.id));
    }

    selectRdmUnavailabilityType(): void {
        this.rdmUnavailabilityTypeSelected = [];
        this._changeRequestAppService.getUnavailabilityTypes()
            .subscribe(x => { this.rdmUnavailabilityType = x; });
    }

    clearUnavailabilityType(item: RdmUnavailabilityDto) {
        this.rdmUnavailabilityTypeSelected = [].concat(this.rdmUnavailabilityTypeSelected.filter(x => x.id !== item.id));
    }

    selectRdmAttachedDocsType(): void {
        this.rdmAttachedDocsTypeSelected = [];
        this._changeRequestAppService.getAttachedDocsTypes()
            .subscribe(x => { this.rdmAttachedDocsType = x; });
    }

    clearRdmAttachedDocsType(item: RdmAttachedDocsDto) {
        this.rdmAttachedDocsTypeSelected = [].concat(this.rdmAttachedDocsTypeSelected.filter(x => x.id !== item.id));
    }

    removerAttachment(anexo: ChangeRequestFilesDto) {
        this.message.confirm('', this.l('RemoverAnexo'), isConfirmed => {
            if (isConfirmed) {
                this.addedAttachments = this.addedAttachments.filter((x) => x.attachmentId !== anexo.attachmentId);
            }
        });
    }

    onFilesAdded(event: any, fileList:FileList | null) {
        const files: File[] = [];
    
        if (fileList != null) {
            for (let i = 0; i < fileList.length; i++) {
                files.push(fileList[i]);
            }
        }
    
        if(event != null){
            const input = event.target as HTMLInputElement;
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    files.push(input.files[i]);
                }
            }
        }
    
        this.uploader = new FileUploader({
            url: AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'
        });
    
        this.uploader.clearQueue();
        if (files.length > 0) {
            this.uploader.addToQueue(files);
        } 
    
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenAppService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this._uploaderOptions.url = AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'
    
        files.forEach(file => {
            this.uploader.onAfterAddingFile = file => {
                file.withCredentials = false;
            };
    
            this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
                this.guidAddedAttachments = uuid.v4();
                form.append('FileType', fileItem.file.type);
                form.append('FileName', fileItem.file.name);
                form.append('FileToken', this.guidAddedAttachments);
            };
    
            this.uploader.setOptions(this._uploaderOptions);
            this.uploader.onSuccessItem = (item, response, status) => {
                const resp = <IAjaxResponse>JSON.parse(response);
                if (resp.success) {
                    let inputBindAttachment: ChangeRequestFilesDto = new ChangeRequestFilesDto();
                    inputBindAttachment.fileType = item.file.type;
                    inputBindAttachment.attachmentName = item.file.name;
                    inputBindAttachment.attachmentId = this.guidAddedAttachments;
                    this.addedAttachments.push(inputBindAttachment);
                } else {
                    this.message.error(resp.error.message);
                }
            };
    
            this.uploader.uploadAll();
        });
    }

    loadTheDepartments(departaments: RdmDepartamentDto[]) {
        this._changeRequestConfigAppService.getAllRdmDepartaments()
            .subscribe((data: any) => {
                this.usersApproval = data.map((item: any) => ({
                label: item.title, 
                data: item,
                selectable: this.rdmSettingsDto.approvalLevelsIsActive ? false : true,
                children: item.mandatoryApproverUsers?.map((sub: any) => ({
                    label: sub.userName,
                    data: sub,
                    selectable: this.rdmSettingsDto.approvalLevelsIsActive ? false : true,
                    children: []
                    })) || []
                }));

                if (this.rdmSettingsDto.approvalLevelsIsActive) {
                    this.usersApprovalSelected = this.mapSelectedNodes(this.usersApproval, data);
                }

                if (departaments != null) {
                    this.usersApprovalSelected = this.mapSelectedNodes(this.usersApproval, departaments);
                    this.cdr.detectChanges();
                }
            });
    }

    mapSelectedNodes(nodes: TreeNode[], selectedData: any[]): TreeNode[] {
        const selectedNodes: TreeNode[] = [];
    
        nodes.forEach((node) => {
          selectedData.forEach(selected => {
                if (selected.id === node.data.id){
                    selectedNodes.push(node);
                }

                if (node.children && node.children.length) {
                    selectedNodes.push(...this.mapSelectedNodesChildren(node.children, selected.mandatoryApproverUsers));
                }
          });
        });
    
        return selectedNodes;
    }

    mapSelectedNodesChildren(nodes: TreeNode[], selectedData: any[]): TreeNode[] {
        const selectedNodes: TreeNode[] = [];
    
        nodes.forEach((node) => {
          if (selectedData.some(selected => selected.id === node.data.id)) {
            selectedNodes.push(node);
          }
        });
    
        return selectedNodes;
    }

    onSelectionChange(event: any): void {
        console.log('Seleção atualizada:', event);
    }

    validateForm() {
        if (this.createChangeRequestDto.title == undefined || this.createChangeRequestDto.title.length == 0) return false;
        if (this.createChangeRequestDto.description == undefined || this.createChangeRequestDto.description.length == 0) return false;
        if (this.createChangeRequestDto.reasonForChange == undefined || this.createChangeRequestDto.reasonForChange.length == 0) return false;
        if (this.createChangeRequestDto.expectedBenefits == undefined || this.createChangeRequestDto.expectedBenefits.length == 0) return false;
        if (this.rdmClassificationTypeSelected == undefined || this.rdmClassificationTypeSelected.length == 0) return false;
        if (this.isEmergency && (this.createChangeRequestDto.mainImpacts == undefined || this.createChangeRequestDto.mainImpacts.length == 0)) return false;
        if (this.rdmUnavailabilityTypeSelected == undefined || this.rdmUnavailabilityTypeSelected.length == 0) return false;
        if (this.rdmAttachedDocsTypeSelected == undefined || this.rdmAttachedDocsTypeSelected.length == 0) return false;
        if (this.usersApprovalSelected == undefined || this.usersApprovalSelected.length == 0) return false;
        if (this.unavailabilityInitialDate == undefined || this.unavailabilityInitialDate == null) return false;
        if (this.unavailabilityEndDate == undefined || this.unavailabilityEndDate == null) return false;

        return true;
    }

    rdmSettings() {
        this._changeRequestConfigAppService.getRdmSettings()
            .subscribe(res => { this.rdmSettingsDto = res });
    }

    calculateHours() {
        if (this.unavailabilityInitialDate && this.unavailabilityEndDate) {
            const diff = this.unavailabilityEndDate.getTime() - this.unavailabilityInitialDate.getTime();
            const totalHours = Math.floor(diff / (1000 * 60 * 60)); 
            const totalMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); 
    
            this.hoursDifference = `${totalHours}h ${totalMinutes}m`;
        } else {
            this.hoursDifference = null;
        }
    }
}