import { Component, EventEmitter, HostListener, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChangeRequestFilesDto, ChangeRequestForApprovalDto, ChangeRequestServiceProxy, CloseChangeRequestDto, EnumRdmRiskLevel, EnumRdmTypeOfChange, RdmAttachedDocsDto, RdmClassificationDto, RdmUnavailabilityDto } from "@shared/service-proxies/service-proxies";
import { FileDownloadService } from "@shared/utils/file-download.service";
import { IAjaxResponse, TokenService } from "abp-ng2-module";
import moment from "moment";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { ModalDirective } from "ngx-bootstrap/modal";
import * as uuid from 'uuid';

interface EventItem { status?: string; icon?: string; }

@Component({
    selector: 'history-change-request-modal',
    templateUrl: './history-change-request-modal.component.html',
    styleUrls: ['./history-change-request-modal.component.less']
})

export class HistorysChangeRequestModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('historysChangeRequestModal', { static: true }) historysChangeRequestModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void { event.preventDefault(); event.stopPropagation(); }
    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void { event.preventDefault(); event.stopPropagation(); }
    @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        
        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
          this.onFilesAdded(null,files);
        }
    }
    
    loading: boolean = false;
    statusChangeRequest: EventItem[] = [];
    statusApproval: EventItem[] = [];
    changeRequestForApprovalDto: ChangeRequestForApprovalDto = new ChangeRequestForApprovalDto;
    unavailabilityEndDate: Date = new Date;
    unavailabilityInitialDate: Date = new Date;
    hoursDifference: string;
    rdmClassificationType: RdmClassificationDto[] = [];
    rdmClassificationTypeSelected: RdmClassificationDto[] = [];
    rdmUnavailabilityType: RdmUnavailabilityDto[] = [];
    rdmUnavailabilityTypeSelected: RdmUnavailabilityDto[] = [];
    rdmAttachedDocsType: RdmAttachedDocsDto[] = [];
    rdmAttachedDocsTypeSelected: RdmAttachedDocsDto[] = [];
    addedAttachments: ChangeRequestFilesDto[] = [];
    guidAddedAttachments: string;
    closeChangeRequestDto: CloseChangeRequestDto = new CloseChangeRequestDto;
    implementationTime: string;
    actualStartDate: Date = new Date;
    actualEndDate: Date = new Date;
    actualHoursDifference: string;
    public uploader: FileUploader;
    private _uploaderOptions: FileUploaderOptions = { url: "" };

    ngOnInit(): void {
        this.setStatusItems();
        this.selectRdmClassificationType();
        this.selectRdmUnavailabilityType();
        this.selectRdmAttachedDocsType();
    }

    constructor(
        injector: Injector,
        private _fileDownloadService: FileDownloadService,
        private _changeRequestAppService: ChangeRequestServiceProxy,
        private _tokenAppService: TokenService,
    ) {
        super(injector);
    }    
    
    async show(changeRequestForApprovalDto: ChangeRequestForApprovalDto) {
        this.rdmClassificationTypeSelected = [];
        this.rdmUnavailabilityTypeSelected = [];
        this.rdmAttachedDocsTypeSelected = [];
        this.addedAttachments = [];
        this.implementationTime = "";
        this.actualStartDate = null;
        this.actualEndDate = null;
        this.actualHoursDifference = '';

        this.changeRequestForApprovalDto = changeRequestForApprovalDto;
        this.unavailabilityInitialDate = changeRequestForApprovalDto.unavailabilityInitialDate.toDate();
        this.unavailabilityEndDate = changeRequestForApprovalDto.unavailabilityEndDate.toDate();
        this.addedAttachments = changeRequestForApprovalDto.filesForClosing;
        this.implementationTime = changeRequestForApprovalDto.implementationTime;

        if (changeRequestForApprovalDto.actualStartDate != undefined)
            this.actualStartDate = changeRequestForApprovalDto.actualStartDate.toDate();

        if (changeRequestForApprovalDto.actualEndDate != undefined)
            this.actualEndDate = changeRequestForApprovalDto.actualEndDate.toDate();

        this._changeRequestAppService.getSelectedClassificationTypes(changeRequestForApprovalDto.id)
            .subscribe(x => { this.rdmClassificationTypeSelected = x; });   

        this._changeRequestAppService.getSelectedUnavailabilityTypes(changeRequestForApprovalDto.id)
            .subscribe(x => { this.rdmUnavailabilityTypeSelected = x; });               

        this._changeRequestAppService.getSelectedAttachedDocsTypes(changeRequestForApprovalDto.id)
            .subscribe(x => { this.rdmAttachedDocsTypeSelected = x; }); 

        this.calculateHours();
        this.calculateHoursActual();

        this.historysChangeRequestModal.show();
    }
    
    close(): void {
        this.historysChangeRequestModal.hide();
        this.closed.emit();
    }

    setColorsBackgroundStatus(status: string) {
        switch (status) {
            case 'Implemented':
                return 'background-color: #299F00;';
            case 'Approved':
                return 'background-color: #299F00;';
            case 'UnderAnalysis':
                return 'background-color: #ffa500;';
            case 'Rejected':
                return 'background-color: #FF6464;';
        }
    }

    setColorsBackgroundStatusApproval(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'background-color: #299F00;';
            case 'Aprovado automaticamente':
                return 'background-color: #299F00;';
            case 'Em análise':
                return 'background-color: #ffa500;';
            case 'Reprovado':
                return 'background-color: #FF6464;';
        }
    }

    setStatusItems() { 
        this.statusChangeRequest = [
            { status: 'Implemented', icon: 'fa fa-solid fa-check' },
            { status: 'Approved', icon: 'fa fa-solid fa-check' },
            { status: 'UnderAnalysis', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Rejected', icon: 'fa fa-solid fa-times' },
        ];

        this.statusApproval = [
            { status: 'Aprovado', icon: 'fa fa-solid fa-check' },
            { status: 'Aprovado automaticamente', icon: 'fa fa-solid fa-user-check' },
            { status: 'Em análise', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Reprovado', icon: 'fa fa-solid fa-times' }
        ];
    }

    searchForStatusTranslation (status: string) {
        switch (status) {
            case 'UnderAnalysis':
                return "Em análise";  
            case 'Approved':
                return "Aprovado";
            case 'Rejected':
                return "Reprovado";  
            case 'Implemented':
                return "Implementado";    
            default:
                break;
        }
    }

    downloadAnexo(anexo: ChangeRequestFilesDto) {
        this._fileDownloadService.downloadBinaryFile(
            anexo.fileStorageId,
            anexo.fileType,
            anexo.attachmentName,
            this.appSession.tenantId
        );
    }

    getEnumChangeType(typeOfChange: EnumRdmTypeOfChange) {
        switch (typeOfChange) {
            case EnumRdmTypeOfChange.Standard:
                return "Padrão";
            case EnumRdmTypeOfChange.Normal:
                return "Normal";         
            case EnumRdmTypeOfChange.Emergency:
                return "Emergencial";
            default:
                return "";
        }
    }

    getEnumRiskLevel(riskLevel: EnumRdmRiskLevel) {
        switch (riskLevel) {
            case EnumRdmRiskLevel.Low:
                return "Baixo";
            case EnumRdmRiskLevel.Average:
                return "Médio";         
            case EnumRdmRiskLevel.High:
                return "Alto";
            case EnumRdmRiskLevel.Critical:
                return "Crítico";
            default:
                return "";
        }
    }

    selectRdmClassificationType(): void {
        this.rdmClassificationTypeSelected = [];
        this._changeRequestAppService.getClassificationTypes()
            .subscribe(x => { this.rdmClassificationType = x; });
    }

    selectRdmUnavailabilityType(): void {
        this.rdmUnavailabilityTypeSelected = [];
        this._changeRequestAppService.getUnavailabilityTypes()
            .subscribe(x => { this.rdmUnavailabilityType = x; });
    }

    selectRdmAttachedDocsType(): void {
        this.rdmAttachedDocsTypeSelected = [];
        this._changeRequestAppService.getAttachedDocsTypes()
            .subscribe(x => { this.rdmAttachedDocsType = x; });
    }

    calculateHours() {
        if (this.unavailabilityInitialDate && this.unavailabilityEndDate) {
            const diff = this.unavailabilityEndDate.getTime() - this.unavailabilityInitialDate.getTime();
            const totalHours = Math.floor(diff / (1000 * 60 * 60)); 
            const totalMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); 
    
            this.hoursDifference = `${totalHours}h ${totalMinutes}m`;
        } else {
            this.hoursDifference = null;
        }
    }

    calculateHoursActual() {
        if (this.actualStartDate && this.actualEndDate) {
            const diff = this.actualEndDate.getTime() - this.actualStartDate.getTime();
            const totalHours = Math.floor(diff / (1000 * 60 * 60)); 
            const totalMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); 
    
            this.actualHoursDifference = `${totalHours}h ${totalMinutes}m`;
        } else {
            this.actualHoursDifference = null;
        }
    }

    removerAttachment(anexo: ChangeRequestFilesDto) {
        this.message.confirm('', this.l('RemoverAnexo'), isConfirmed => {
            if (isConfirmed) {
                this.addedAttachments = this.addedAttachments.filter((x) => x.attachmentId !== anexo.attachmentId);
            }
        });
    }

    onFilesAdded(event: any, fileList:FileList | null) {
        const files: File[] = [];
    
        if (fileList != null) {
            for (let i = 0; i < fileList.length; i++) {
                files.push(fileList[i]);
            }
        }
    
        if(event != null){
            const input = event.target as HTMLInputElement;
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    files.push(input.files[i]);
                }
            }
        }
    
        this.uploader = new FileUploader({
            url: AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'
        });
    
        this.uploader.clearQueue();
        if (files.length > 0) {
            this.uploader.addToQueue(files);
        } 
    
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenAppService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this._uploaderOptions.url = AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'
    
        files.forEach(file => {
            this.uploader.onAfterAddingFile = file => {
                file.withCredentials = false;
            };
    
            this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
                this.guidAddedAttachments = uuid.v4();
                form.append('FileType', fileItem.file.type);
                form.append('FileName', fileItem.file.name);
                form.append('FileToken', this.guidAddedAttachments);
            };
    
            this.uploader.setOptions(this._uploaderOptions);
            this.uploader.onSuccessItem = (item, response, status) => {
                const resp = <IAjaxResponse>JSON.parse(response);
                if (resp.success) {
                    let inputBindAttachment: ChangeRequestFilesDto = new ChangeRequestFilesDto();
                    inputBindAttachment.fileType = item.file.type;
                    inputBindAttachment.attachmentName = item.file.name;
                    inputBindAttachment.attachmentId = this.guidAddedAttachments;
                    this.addedAttachments.push(inputBindAttachment);
                } else {
                    this.message.error(resp.error.message);
                }
            };
    
            this.uploader.uploadAll();
        });
    }

    closeChangeRequest() {
        if (this.actualStartDate == null){
            this.notify.warn("A data inicial não pode ser vazia.");
            this.loading = false;
            return;
        }

        if (this.actualEndDate == null){
            this.notify.warn("A data final não pode ser vazia.");
            this.loading = false;
            return;
        }

        if (moment(this.actualStartDate).isBefore(moment())) {
            this.notify.warn("A data informada não pode ser anterior a hoje.");
            this.loading = false;
            return;
        }

        if (this.actualEndDate < this.actualStartDate){
            this.notify.warn('Data final não pode ser menor que a inicial.');
            this.loading = false;
            return;
        }

        this.closeChangeRequestDto = new CloseChangeRequestDto();
        this.closeChangeRequestDto.changeRequestId = this.changeRequestForApprovalDto.id;
        this.closeChangeRequestDto.filesForClosing = this.addedAttachments;
        this.closeChangeRequestDto.actualStartDate = moment(this.unavailabilityInitialDate);
        this.closeChangeRequestDto.actualEndDate = moment(this.unavailabilityEndDate);       
        this.closeChangeRequestDto.implementationTime = this.actualHoursDifference;

        this._changeRequestAppService.closeChangeRequest(this.closeChangeRequestDto)
            .subscribe(res => { this.close() });
    }
}   