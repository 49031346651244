<iframe class="col-12 mt-4" frameborder="0" height="600" allowtransparency [src]="urlQuestion2"></iframe>

<iframe class="col-12 mt-4" frameborder="0" height="600" allowtransparency [src]="urlQuestion1"></iframe>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion3"></iframe>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion4"></iframe>

<iframe class="col-12 col-md-8 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion5"></iframe>

<iframe class="col-12 col-md-4 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion6"></iframe>

<iframe class="col-12 col-md-9 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion7"></iframe>

<iframe class="col-12 col-md-3 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion8"></iframe>

<iframe class="col-12 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion9"></iframe>