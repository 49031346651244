import { Component, Injector, Input, OnInit } from "@angular/core";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AvaliacaoDeAmbienteServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    selector: "app-environment-assessment",
    templateUrl: "./environment-assessment.component.html",
    styleUrls: ["./environment-assessment.component.less"],
})
export class EnvironmentAssessmentComponent
    extends AppComponentBase
    implements OnInit
{
    @Input() tenantId: number = 0;
    urlQuestion1: SafeUrl;
    urlQuestion2: SafeUrl;
    urlQuestion3: SafeUrl;
    urlQuestion4: SafeUrl;
    urlQuestion5: SafeUrl;
    urlQuestion6: SafeUrl;
    urlQuestion7: SafeUrl;
    urlQuestion8: SafeUrl;
    urlQuestion9: SafeUrl;
    selectedDate: string = "";

    constructor(
        injector: Injector,
        private domSanitizer: DomSanitizer,
        private avaliacaoDeAmbienteService: AvaliacaoDeAmbienteServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getDateTechnicalDebits();
    }

    async getDateTechnicalDebits() {
        await this.avaliacaoDeAmbienteService
            .getDatesObjectsExtracts(this.tenantId)
            .subscribe((resp) => {
                this.selectedDate = resp[0];
                this.mounthGraphics();
            });
    }

    mounthGraphics() {
        this.urlQuestion1 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/73f3b410-b870-41c1-ba03-8c8703b735e2?TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion2 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/189155eb-c2b9-4d14-9327-9af50e06aa88?TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion3 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/db3d8efb-45f0-49d3-b13e-10b238b8e108?TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion4 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/84dd1225-aae4-4248-aaf9-20798aa7f898?TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion5 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/de3aa7a3-fe5b-48e3-9265-bdbc619c1135?TenantId=${this.appSession.tenantId}&Date=${this.selectedDate}#hide_parameters=TenantId,Date&theme=transparent`
        );
        this.urlQuestion6 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/3becee9c-d065-4770-919e-e327062ab8d8?TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion7 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/58fc07b6-77ca-4865-b26e-daac8bb22ab2?TenantId=${this.appSession.tenantId}&Date=${this.selectedDate}#hide_parameters=TenantId,Date&theme=transparent`
        );
        this.urlQuestion8 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/31e72cfc-791b-499e-9638-5ba653d4614a?TenantId=${this.appSession.tenantId}&Date=${this.selectedDate}#hide_parameters=TenantId,Date&theme=transparent`
        );
        this.urlQuestion9 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/25467e05-817e-4656-b002-7859816db723?TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
    }
}
