import { Component, EventEmitter, Injector, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AlteracaoDeStatusDaDemandaDto, AssignmentGroupDto, CreateOrEditGestaoDeMudancaNativaDto, CreateOrEditGestaoMudancaDto, EnumNautrezaGmud, EnumRiscoGmud, GestaoMudancaRiscoViewDto, GestaoMudancaServiceProxy, JanelaDeDeployServiceProxy, TipoMudanca, TransportDataDto } from '@shared/service-proxies/service-proxies';
import moment from 'moment/moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { InformationDeployModalComponent } from '../gestaoMudancas/create-change-manager-native-modal/information-deploy/informationDeploy.component';

interface Types {
  name: string;
  value: string;
}

@Component({
  selector: 'app-create-change-manager-modal',
  templateUrl: './create-change-manager-modal.component.html',
  styleUrls: ['./create-change-manager-modal.component.css']
})
export class CreateChangeManagerModalComponent extends AppComponentBase implements OnInit {
  @ViewChild('changeManagerModal', { static: true }) modal: ModalDirective;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() manualChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('informationDeployModal', { static: true }) informationDeployModal: InformationDeployModalComponent;

  changeManager: CreateOrEditGestaoMudancaDto = new CreateOrEditGestaoMudancaDto();
  alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto = new AlteracaoDeStatusDaDemandaDto();
  saving: boolean = false;
  assignmentGroups: AssignmentGroupDto[] = [];
  configurationItems: AssignmentGroupDto[] = [];
  loading: boolean = false;
  validations = {
    risk: false
  };

  dataInicio: moment.Moment = moment();
  dataFim: moment.Moment = moment();
  types: Types[] = [];
  isWindowsTransport: boolean = false;
  windowsTransport: string = "";
  datetimeDeploy: Date;
  isPlanned: boolean = false;
  isNotFreezing: boolean = false;

  get enumNautrezaGmud() {
    return EnumNautrezaGmud;
  }

  get enumRiscoGmud() {
    return EnumRiscoGmud;
  }

  get isInvalid() {
    return Object.keys(this.validations).map(key => this.validations[key]).some(item => !item)
  }

  constructor(injector: Injector, 
              private _service: GestaoMudancaServiceProxy, 
              private messageService: MessageService,
              private _deployService: JanelaDeDeployServiceProxy) {
    super(injector);
  }

  ngOnInit() {
    this.setValues();
    this.types = [
      { name: 'Padrão', value: TipoMudanca.Padrao }, 
      { name: 'Emergencial', value: TipoMudanca.Emergencial }
    ];
  }

  async searchAssignmentGroup() {
    return new Promise<AssignmentGroupDto[]>((resolve, reject) =>
      this._service.changeManagerSearchAssignmentGroup().subscribe(result => {
        resolve(result)
      })
    )
  }

  async searchConfigurationItem() {
    return new Promise<AssignmentGroupDto[]>((resolve, reject) =>
      this._service.searchConfigurationItem().subscribe(result => {
        resolve(result)
      })
    )
  }

  setValues() {
    let changeManager = new CreateOrEditGestaoMudancaDto();
    changeManager.tipoMudanca = this.l('Normal');
    changeManager.categoria = this.l('Productivity');
    changeManager.acessoBase = this.l('Not');
    changeManager.justificativa = this.l('QAMetrikToolUsing');
    changeManager.gestaoMudancaRisco = new GestaoMudancaRiscoViewDto();
    changeManager.gestaoMudancaRisco.planoMudanca = this.l('QADevOpsProductionPublishing');
    changeManager.gestaoMudancaRisco.planoTeste = this.l('TechnicalAreaValidation');
    changeManager.planoRetorno = this.l('ReturnPlanValue');
    this.changeManager = changeManager;
    this.isWindowsTransport = false;
    this.isPlanned = false;
    this.datetimeDeploy = null;
  }

  close(): void {
    this.setValues();
    this.modal.hide();
    this.closed.emit();
  }

  async show(demandaId: string, alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto) {
    this._service.getDadosDemanda(demandaId)
        .subscribe(res => {
          var transportDataDto: TransportDataDto = new TransportDataDto;
          transportDataDto.transportType = TipoMudanca.Emergencial;
          transportDataDto.demandaId = demandaId;
          transportDataDto.id = "00000000-0000-0000-0000-000000000000";
          transportDataDto.dateTimeTransport = moment().local();
    
          this._deployService.checkTypesOfTransport(transportDataDto)
          .subscribe(resCheck => {
            if (resCheck == "") {
              this.isNotFreezing = true;
            }

            this.loading = true;
            this.assignmentGroups = null;//await this.searchAssignmentGroup();
            this.configurationItems = null; //await this.searchConfigurationItem();
            this.changeManager.demandaId = demandaId;
            this.changeManager.titulo = res.titulo;
            this.changeManager.descricao = res.descricao;
            this.alteracaoDeStatusDaDemandaDto = alteracaoDeStatusDaDemandaDto;
            this.loading = false;
            this.changeManager.tipoMudancaNativa = TipoMudanca.Padrao;
            this.modal.show();
          });
        })
  }

  save() { 
    if (this.changeManager.descricao == null || this.changeManager.descricao == undefined || this.changeManager.descricao == '')
      return this.showError('warn', 'Atenção', 'A descrição da demanda deve ser preenchida, acesse a demanda e preencha o campo.');

    if (this.changeManager.comentario == null || this.changeManager.comentario == undefined || this.changeManager.comentario == '')
      return this.showError('warn', 'Atenção', 'O comentário da mudança não pode ser em branco.');

    if (this.changeManager.uDescricao == null || this.changeManager.uDescricao == undefined || this.changeManager.uDescricao == '')
      return this.showError('warn', 'Atenção', 'A descrição da mudança não pode ser em branco.');
    
    if (this.changeManager.dataFim < this.changeManager.dataInicio)
      return this.showError('warn', 'Atenção', 'Horário do fim da mudança deve ser maior que o horário do início da mundança, ajuste os minutos.'); 

    if (this.changeManager.tipoMudancaNativa == TipoMudanca.Planejado)
      this.changeManager.transpDate = moment(this.datetimeDeploy);

    var transportDataDto: TransportDataDto = new TransportDataDto;
    transportDataDto.transportType = this.changeManager.tipoMudancaNativa;
    transportDataDto.demandaId = this.alteracaoDeStatusDaDemandaDto.demandaId;
    transportDataDto.id = "00000000-0000-0000-0000-000000000000";
    transportDataDto.dateTimeTransport = moment(this.changeManager.dataInicio);

    this._deployService.checkTypesOfTransport(transportDataDto)
    .subscribe(res => {
      if (res == "") {
        this._service.createOrEdit(this.changeManager).subscribe(() => {
          this.setValues();
          this.modal.hide();
          this.saved.emit(this.alteracaoDeStatusDaDemandaDto);
        });
      } else {
        this.informationDeployModal.show(res, null, this.changeManager, this.alteracaoDeStatusDaDemandaDto, false, moment(this.datetimeDeploy), true);
      }
    });
  }

  showError(severity: string, summary: string, detail: string) {
    this.messageService.add({ 
        severity: severity, 
        summary: summary, 
        detail: detail, 
        life: 5000 
    });
  }

  showErrorTypeChange() {
    this.changeManager.tipoMudancaNativa = TipoMudanca.Padrao;
    this.messageService.add({ 
        severity: 'warn', 
        summary: 'Atenção', 
        detail: 'Fora do periodo de freezing não é possivel utilizar mudança Emergencial.', 
        life: 5000 
    });
  }

  manualChangeEvent() {
    this.manualChange.emit(this.alteracaoDeStatusDaDemandaDto);
    this.close();
  }

  onValueChange(newValue: any) {
    this.changeManager.tipoMudancaNativa = newValue.value;

    if (newValue.value == TipoMudanca.JanelaDeTransporte){
      this._deployService.getScheduling()
          .subscribe(res => { this.windowsTransport = res.dateTimeTransport });
    }
    
    this.isWindowsTransport = newValue.value == TipoMudanca.JanelaDeTransporte ? true : false;
    this.isPlanned = newValue.value == TipoMudanca.Planejado ? true : false;

    /*
    if (newValue.value == TipoMudanca.Emergencial)
    {
      var transportDataDto: TransportDataDto = new TransportDataDto;
      transportDataDto.transportType = this.changeManager.tipoMudancaNativa;
      transportDataDto.demandaId = this.alteracaoDeStatusDaDemandaDto.demandaId;
      transportDataDto.id = "00000000-0000-0000-0000-000000000000";
      transportDataDto.dateTimeTransport = moment(this.changeManager.dataInicio);

      this._deployService.checkTypesOfTransport(transportDataDto)
      .subscribe(res => {
        if (res == "") {
          this.changeManager.tipoMudancaNativa = TipoMudanca.Padrao;
          newValue.value = TipoMudanca.Padrao;
          return this.showErrorTypeChange();
        }
      });
    }
    */
  }

  closeInformation() {
    this.loading = false;
  }

  finalizeDemand() {
    this.setValues();
    this.loading = false;
    this.modal.hide();
    this.saved.emit(this.alteracaoDeStatusDaDemandaDto);
  }
}

