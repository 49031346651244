<form (ngSubmit)="saveAll()">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"Importação de STMS" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"Configuração de importação de STMS" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button class="btn btn-primary" type="submit">
                    <i class="la la-floppy-o"></i>
                    {{"SaveAll" | localize}}
                </button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet">
            <div id="spinner-loading" class="spinner-loading">
                <p-progressSpinner *ngIf="loading; else portletBody"></p-progressSpinner>
            </div>
            <ng-template #portletBody>
                <div class="kt-portlet__body">
                    <label for="active-stms-async" class="kt-checkbox">
                        <input id="active-stms-async" type="checkbox" name="ActiveStmsImport"
                            [(ngModel)]="activeStmsImport.activeStmsImportAsync"> {{"Habilitar importação de STMS" | localize}}
                        <span></span>
                    </label>
                    <label for="active-prod-stms" class="kt-checkbox">
                        <input id="active-prod-stms" type="checkbox" name="ActiveProdStms"
                            [(ngModel)]="activeStmsImport.activeStmsImportForProductionAsync"> {{"Habilitar importação de STMS em Produção" | localize}}
                        <span></span>
                    </label>
                    <div class="form-group">
                        <label for="numberOfAttempt" class="form-label" style="display: block; text-align: left;">Tentativas para STMS</label>
                        <input id="numberOfAttempt" type="number" name="numberOfAttempt" class="form-control w-25" 
                               [(ngModel)]="activeStmsImport.numberOfAttempts" required min="0" #NumberOfAttempt="ngModel">
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</form>